import styled from "styled-components";

export const StyledPage = styled.div`
  width: 100%;
`;

export const RecordContainer = styled.div`
  bottom: 0;
  position: absolute;
  height: min-content;
  bottom: -8rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
`;
